.App {
  display: flex;
  height: 100vh;
  background-color: #fafafa;
  /*overflow: hidden;*/
}

.loginBox {
  padding: 25px 20px;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loginBox h2 {
  margin-bottom: 33px;
  font-size: 30px;
  color: #555555;
  line-height: 1.2;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 10;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

@media (min-width: 992px) {
  .loginBox {
    padding: 65px 50px 60px;
  }
}
